require('./bootstrap');

import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum'
import {Web3Modal} from '@web3modal/html'
import {configureChains, createConfig, getAccount, getContract} from '@wagmi/core'
import {arbitrum, mainnet, polygon} from '@wagmi/core/chains'

const chains = [arbitrum, mainnet, polygon]
const projectId = '7f645ecd4d880970dcc09836c9688e67'

const {publicClient} = configureChains(chains, [w3mProvider({projectId})])
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({projectId, chains}),
    publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)
const web3modal = new Web3Modal({projectId}, ethereumClient)

setInterval(function () {
    let {address} = getAccount();
    if (address) {
        Livewire.emit('saveWalletAddress', [address]);
    } else {
        Livewire.emit('saveWalletAddress', ['']);
    }
}, 2000);
